import axios from "axios";

export default {
    /**
     * Get appuntamenti squadre for the logged user
     */
    async getAppuntamenti(userID: string) {
        const data = new FormData();
        data.append("limit", "150");
        data.append("where[]", `appuntamenti_id IN (SELECT appuntamenti_id FROM rel_appuntamenti_persone WHERE users_id = '${userID}')`);
        data.append("where[]", `(appuntamenti_da_confermare IS NULL OR appuntamenti_da_confermare = '' OR appuntamenti_da_confermare = '0')`);
        data.append("orderby", "appuntamenti_giorno");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/appuntamenti", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response.data;
    },

    async getAppuntamentiNoRapportino(dipendenteID: string) {
        const data = new FormData();
        data.append("dipendente", dipendenteID);

        //const response = await axios.post(`http://192.168.11.124/firegui_puma/custom/app/appuntamenti`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}rapportini/rapportini/appuntamenti`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        console.log(response);
        return response;
    },
};
